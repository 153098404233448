.swiping{
    animation: swipe-right 1s ease-in-out infinite alternate;
}
@keyframes swipe-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10px);
  }
}

.code-container {
    position: relative;
    display: inline-block;
  }
  
  .code-container:hover::before {
   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }


  @keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.animate-bounce {
  animation: bounce 1s ease-in-out infinite;
}


.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 10%;
  transform: translateY(150%);
 
  border-radius: 0%;
  background-color: transparent;
  color: #fff;
  border: px solid #fff;
  cursor: pointer;
  font-size: 20px;
  padding:2px;
  text-align: center;
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
  

}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #fff;
  color: #000;
}

.swiper-pagination-bullet{
  transform: translateY(140%);
  background-color: black;


  
}
